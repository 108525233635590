ul.pagination {
  display: inline-flex;

  li {
    & > a, & > span {
      padding: 6px 12px;
      text-decoration: none;
      color: $jma-gold;
      background-color: #fff;
      border: 1px solid #ddd;
      margin-left: -1px;

      &:hover, &:focus {
        color: darken($color: $jma-gold, $amount: 20);
        background-color: #eee;
        border-color: #ddd;
      }
    }

    &.active {
      & > a, & > span {
        color: #fff;
        background-color: $jma-gold;
        border-color: $jma-gold;
        cursor: default;
      }
    }

    a, span {
      .fa-ellipsis-h {
        vertical-align: bottom;
      }
    }

    &:first-child {
      & > a, & > span {
        border-bottom-left-radius: $border-radius;
        border-top-left-radius: $border-radius;
      }
    }

    &:last-child {
      & > a, & > span {
        border-bottom-right-radius: $border-radius;
        border-top-right-radius: $border-radius;
      }
    }
  }
}

.pager {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 15px;
  padding-right: 30px;
}