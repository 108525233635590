
.links-container {
  &.fullwidth {
    width: 100%;
  }
  text-align: center;
  flex-grow: 1;

  #mobile-menu, .mobile-menu-toggle {
    display: none;
  }

  .links {
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: flex-start;
    
    .menu-link {
      padding: 15px;
      display: inline-block;

      &:hover, &.active {
        text-decoration: none;
        z-index: 1001;
      }
    }
    .submenu {
      position: relative;
      display: inline-block;

      input[type="checkbox"] {
        display: none;
      }
      .sub-menu-links {
        max-height: 0;
      }
    }
  }

  @media screen and (min-width: 950px) {
    .links>div:not(:last-child) {
      margin-right: 20px;
    }

    .submenu {
      & > a:after {
        font-family: "Font Awesome 5 Pro"; 
        font-weight: 900; 
        content: "\f0dd";
        padding: 0 5px;
      }
      .sub-menu-links {
        display: flex;
        flex-flow: column;
        position: absolute;
        width: 100%;
        background-color: #FFF;
        transition: max-height 0.15s ease-in;

        .submenu {
          position: relative;

          &:hover .sub-menu-links {
            left: 100%;
            top: 0;
          }
        }
      }
      
      &:not(:hover) .sub-menu-links a {
        color: transparent;
        display: none;
      }
    }
    .submenu:hover .sub-menu-links {
      max-height: 500px;
      transition: max-height 0.5s ease-out;
      a {
        margin: 0;
      }
    }
  }

  @media screen and (max-width: 950px) {
    & {
      width: 100%;
      width: 100%;
      text-align: center;
      -webkit-box-flex: 1;
      flex-grow: 1;
    }
    .mobile-menu-toggle {
      display: block;
    }

    .links {
      flex-flow: column;
      max-height: 0;
      transition: max-height 0.15s ease-out;
      overflow: hidden;

      a, .submenu {
        width: 100%;
        margin: 0;
      }

      .submenu {
        input[type="checkbox"] {

          &:checked~label .sub-menu-icon .bar1  {
            transform: rotate(-135deg);
          }
          &:checked~label .sub-menu-icon .bar2 {
            transform: rotate(-135deg);
          }
          
          &:checked~.sub-menu-links {
            max-height: 500px;
            transition: max-height .25s ease-in;            
            a {
              transition: color .25s ease-in;
            }
          }
          &:not(:checked)~.sub-menu-links {
            max-height: 0;
            a {
              color: transparent;
            }
          }
        }
        > label {
          position: absolute;
          right: 10px;
          top: 10px;
          .sub-menu-icon {
            width: 25px;
            height: 25px;
            position: relative;
            
            .bar1, .bar2 {
              background-color: #777;
              transition: .4s;
              position: absolute;
            }
            .bar1 {
              width: 100%;
              height: 5px;
              margin: 0 auto;
              top: calc(50% - 2.5px);
            }
            .bar2 {
              height: 100%;
              width: 5px;
              margin: auto 0;
              left: calc(50% - 2.5px);
            }
          }
        }
        .sub-menu-links {
          background-color: #00000025;
        }
      }
    }
    label[for="mobile-menu"] {
      color: #000;
      font-weight: bold;
      text-transform: uppercase;
      margin: 0;
      padding: 15px;
      width: 100%;
      cursor: pointer;
      display: inline-block;
      position: relative;

      .menu-icon {
        position: absolute;
        left: 8px;
        top: 0;
        bottom: 0;
        width: 35px;
        display: flex;
        flex-flow: column;
        justify-content: center;
        
        .bar1, .bar2, .bar3 {
          width: 100%;
          height: 5px;
          background-color: #000;
          margin: 4px 0;
          transition: 0.4s;
        }
      }
    }
    #mobile-menu:checked {
      ~ .links {
          max-height: 1000px;
          transition: max-height 0.25s ease-in;
      }

      ~ label[for="mobile-menu"] .menu-icon {
        .bar1 { transform: rotate(-45deg) translate(-9px, 9px); }
        .bar2 { opacity: 0; }
        .bar3 { transform: rotate(45deg) translate(-9px, -9px); }
      }
    }
  }
  
  @import './_submenu.scss';
}