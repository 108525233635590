@import "select2/src/scss/core.scss";

$select2-dropdown-arrow-padding: 20px;

select.select2.form-control:not(.defined-width) + .select2-container {
  width: 100% !important;
}

select.select2.form-control {
  &:disabled,
  &[readonly] {
    & + .select2-container {
      background-color: $input-disabled-bg;
      opacity: 1;
    }
  }
}

.select2-container .select2-selection--single { height: auto; }

.select2-container--default {
  .select2-selection--single {
    height: auto;
    min-height: 2.375rem;
    border: $input-border-width solid $input-border-color;
    display: flex;
    align-items: center;
    @if $enable-rounded {
      border-radius: $input-border-radius;
    } @else {
      border-radius: 0;
    }

    @include box-shadow($input-box-shadow);
    @include transition($input-transition);

    @include form-control-focus($ignore-warning: true);

    .select2-selection__rendered {
      padding: $input-padding-y 0 $input-padding-y $input-padding-x;
      font-size: $font-size-base;
      line-height: $input-line-height;
      color: $input-color;
      flex: 1 1 auto;
    }
    .select2-selection__arrow {
      // height: 32px;
      height: auto;
      position: relative;
      align-self: stretch;
      padding: $input-padding-y 0;
    }
  }

  .select2-selection--multiple {
    min-height: 2.375rem;
    border: $input-border-width solid $input-border-color;
    @include box-shadow($input-box-shadow);
    @include transition($input-transition);

    @include form-control-focus($ignore-warning: true);
  }

  &.select2-container--focus {
    .select2-selection--multiple {
      border: $input-border-width solid $input-focus-border-color;
      color: $input-focus-color;
      background-color: $input-focus-bg;
      outline: 0;
      // Avoid using mixin so we can pass custom focus shadow properly
      @if $enable-shadows {
        box-shadow: $input-box-shadow, $input-focus-box-shadow;
      } @else {
        box-shadow: $input-focus-box-shadow;
      }
    }
  }
}

.select2-dropdown {
  border: $input-border-width solid $input-border-color;
}
.select2-container--default .select2-search--dropdown .select2-search__field {
  border: $input-border-width solid $input-border-color;
}

.select2-search--dropdown .select2-search__field {
  padding: $input-padding-y $input-padding-x;
  font-size: $font-size-base;
  line-height: $input-line-height;
  color: $input-color;
}

// .select2-container--default.select2-container--focus .select2-selection--multiple,
// .select2-container--default .select2-selection--multiple {
  
// }

.select2-results__option {
  padding: $input-padding-y $input-padding-x;
  font-size: $font-size-base;
  line-height: $input-line-height;
}

.select2-results {
  .option-group { font-weight: bold; }
  .option-child { padding-left: .9375rem; }

  .select2-create {
    padding: .375rem;
    user-select: none;
    cursor: pointer;
    display: block;
    font-style: italic;
  }
}

select.select2.form-control-sm {

  & ~ .select2-container--default {
    .select2-selection__rendered {
      padding: $input-padding-y-sm 0 $input-padding-y-sm $input-padding-x-sm;
      font-size: $font-size-sm;
      line-height: $input-line-height-sm;
    }

    .select2-selection--single {
      min-height: 1.9375rem;
      @include border-radius($input-border-radius-sm);

      .select2-selection__arrow {
        padding: $input-padding-y-sm 0;
      }
    }

    .select2-selection--multiple {
      min-height: 1.9375rem;
      line-height: 0;
      @include border-radius($input-border-radius-sm);

      .select2-selection__rendered {
        padding: 0 0 calc(#{$input-padding-y-sm} - 1px) $input-padding-x-sm;
      }

      .select2-selection__choice {
        margin-top: calc(#{$input-padding-y-sm} - 1px);
      }

      .select2-search--inline {
        .select2-search__field {
          margin-top: calc(#{$input-padding-y-sm} - 1px);
        }
      }
    }
  }

}

.sel2-form-control-sm {
  .select2-search--dropdown .select2-search__field, .select2-results__option {
    padding: $input-padding-y-sm $input-padding-x-sm;
    font-size: $font-size-sm;
    line-height: $input-line-height-sm;
  }
}

.sel2-form-control-lg {
  .select2-search--dropdown .select2-search__field, .select2-results__option {
    padding: $input-padding-y-lg $input-padding-x-lg;
    font-size: $font-size-lg;
    line-height: $input-line-height-lg;
  }
}

.select2-container.text-transform-uppercase {
  .select2-results__option {
    text-transform: uppercase;
  }
  
  .select2-search--dropdown .select2-search__field {
    text-transform: uppercase;
    &::placeholder {
      text-transform: none;
    }
  }
}

select.select2.form-control-lg {

  & ~ .select2-container--default .select2-selection--single {
    min-height: 3rem;
    @include border-radius($input-border-radius-lg);

    .select2-selection__rendered {
      padding: $input-padding-y-lg 0 $input-padding-y-lg $input-padding-x-lg;
      font-size: $font-size-lg;
      line-height: $input-line-height-lg;
    }
    .select2-selection__arrow {
      padding: $input-padding-y-lg 0;
    }
  }

}


select.select2.form-control.is-invalid ~ .select2-container,
.was-validated select.select2.form-control:invalid ~ .select2-container {
  .select2-selection--single {
    border-color: $form-feedback-invalid-color;

    &:focus {
      border-color: $form-feedback-invalid-color;
      box-shadow: 0 0 0 $input-focus-width rgba($form-feedback-invalid-color, .25);
    }
  }

  &:hover, &:focus-within {
    & ~ .invalid-feedback, & ~ .invalid-tooltip {
      display: block;
    }
  }
  
}

.input-group {
  select.select2.form-control:not(.defined-width) ~ .select2-container {
    width: auto !important;
  }

  select.select2.form-control:first-child ~ .select2-container:not(:last-child) {
    .select2-selection--single {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  select.select2.form-control:not(:first-child) ~ .select2-container {
    .select2-selection--single {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

fieldset:disabled {
  .select2-container {
    pointer-events: none;

    .select2-selection--single {
      background-color: $input-disabled-bg;
      cursor: default;
  
      .select2-selection__clear {
        display: none;
      }
    }

    .select2-selection--multiple {
      background-color: $input-disabled-bg;
      cursor: default;
    }
  
    .select2-selection__choice__remove {
      display: none;
    }
  }
}

.form-control.select2:not(.select2-hidden-accessible)[multiple] {
  height: calc(2.25rem + 2px);

  &.form-control-sm {
    height: calc(2rem - 1px);
  }

  &.form-control-lg {
    height: calc(2.5rem + 8px);
  }
}