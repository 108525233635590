#myStatements {

  #statement-list {
    a.statement {
      grid-template-columns: auto 1fr 7rem;
      grid-template-areas:
        "number . total"
        "title . balance"
        "date . status";
    }

    .statement-number { grid-area: number; }
    .auction-title { grid-area: title; }
    .statement-date { grid-area: date; }
    .statement-status { grid-area: status; text-align: right; }
    .statement-total { grid-area: total; text-align: right; }
    .statement-balance { grid-area: balance; text-align: right; }

    @include media-breakpoint-up(md) {
      header, a.statement {
        display: grid;
        grid-template-columns: 7rem 1fr 5.25rem 5rem 7rem 7rem;
        grid-template-areas:
          "number title date status total balance";
        
        .statement-date { text-align: center; }
        .statement-status { text-align: center; }
      }
    }
    @include media-breakpoint-up(lg) {
      header, a.statement {
        grid-template-columns: 9rem 1fr 5.25rem 5rem 7rem 7rem;
      }
    }

    header.statement {
      .statement-total { text-align: center; }
      .statement-balance { text-align: center; }
    }
  }
}

#statementDetails {
  dl.field-grid {
    dt { float: left; margin-right: .5em; font-weight: normal; }
    dd {
      margin-left: 5em;
      margin-bottom: 0;
      &::after {
        content: '';
        display: block;
        clear: left;
      }
    }
  }

  #statement-header-details {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 1fr;
    grid-column-gap: .5em;
    grid-template-areas:
      "stmt-date"
      "auction-info";

    .statement-date { grid-area: stmt-date; text-align: right; }
    .seller-information { grid-area: seller-info; display: none; }
    .auction-info { grid-area: auction-info; }

    @include media-breakpoint-up(md) {
      grid-template-columns: 1fr 1fr;
      grid-template-areas:
        "stmt-date stmt-date"
        "seller-info auction-info";

      .seller-information {
        display: block;
      }
    }
  }

  #statement-items {
    display: grid;
    grid-template-rows: auto;
    min-height: calc(100px + 1em);

    .statement-lot {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: auto;
      grid-template-areas:
        "id lotnmbr bid amount"
        "description description description description"
        "expenses expenses expenses expenses";

      &:nth-child(even) {
        background-color: $alt-back-color;
        color: $alt-text-color;
      }
      & > * { padding: 0 5px; }
    }

    .statement-lot + .statement-lot {
      border-top: 1px solid $section-header-background-color;
    }

    .statement-lot + .statement-lot.sublot {
      border-top: none;
    }
    .statement-lot.sublot {
      padding-left: 1.5rem;
    }

    .id {
      grid-area: id;
      text-align: right;
    }
    .lot-number {
      grid-area: lotnmbr;
      text-align: right;
      &::before {
        content: '#';
      }
    }
    .description { grid-area: description; }
    .bid {
      grid-area: bid;
      padding-left: 0;
      text-align: right;
      &::before {
        padding-right: 5px;
      }
    }
    .amount {
      grid-area: amount;
      text-align: right;
    }

    header.statement-lot {
      font-weight: bold;
      .amount { text-align: right; }
    }

    @include media-breakpoint-up(md) {
      .statement-lot {
        display: grid;
        grid-template-columns: 4rem 4rem auto 12rem 12rem;
        grid-template-areas:
          "id lotnmbr description bid amount"
          "expenses expenses expenses expenses expenses";

        .id {
          text-align: left;
          &::before { content: ''; }
        }
        .lot-number {
          text-align: left;
          &::before { content: ''; }
        }
        .bid {
          text-align: right;
          &::before { content: ''; }
        }
      }
    }

    footer.totals {
      .total {
        display: grid;
        grid-template-columns: 1fr auto 7rem;
        grid-template-areas: ". label amount";

        .label {
          grid-area: label;
          font-weight: bold;
        }
        .amount { grid-area: amount; }
      }
    }
  }
}