@import "intl-tel-input/src/css/intlTelInput.scss";

.input-group {
  &.input-group-phone {
    flex-wrap: nowrap;
  }
  
  .intl-tel-input:not(:first-child) {
    input.form-control {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  .intl-tel-input:not(:last-child) {
    input.form-control {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

.phn-flags-form-control-sm {
  .country-list .country {
    padding: $input-padding-y-sm $input-padding-x-sm;
    font-size: $font-size-sm;
    line-height: $input-line-height-sm;
  }
}

.phn-flags-form-control-lg {
  .country-list .country {
    padding: $input-padding-y-lg $input-padding-x-lg;
    font-size: $font-size-lg;
    line-height: $input-line-height-lg;
  }
}
