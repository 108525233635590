.search-bar {
  margin: 0 30px;

  background-color: $alt-back-color;
  color: $alt-text-color;
  padding: 3px 5px;
  border-radius: $border-radius;

  > * + * {
    margin-left: 5px;
  }

  #keywordSearchBox {
    flex-grow: 1;
    min-width: 150px;
  }
  
  .dropdown-item.input-group {
    padding-left: 5px;
    padding-right: 5px;

    &:hover, &:active {
      background-color: transparent;
    }
  }
}

section#propertyListings {
  .tile-info .asking-price {
    font-size: 1.5em;
  }
}