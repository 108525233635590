.modal-dialog {
  max-width: 1000px;
  @include media-breakpoint-up(sm) {
    &.modal-fill {
      width: 90vw;
      max-width: 90vw;
    }
  }
  &.modal-fullscreen {
    width: 98vw;
    max-width: 98vw;

    height: calc(98vh - 3.5rem);
    max-height: calc(98vh - 3.5rem);

    .modal-content {
      height: 100%;

      .modal-body {
        &:not(:first-child):not(:last-child) {
          max-height: calc(100vh - (11.5rem + 2px));
          height: calc(100vh - (11.5rem + 2px));
        }
        &:not(:first-child):last-child {
          max-height: calc(100vh - (7rem + 1px));
          height: calc(100vh - (7rem + 1px));
        }
        &:first-child:not(:last-child) {
          max-height: calc(100vh - (4rem + 1px));
          height: calc(100vh - (4rem + 1px));
        }
        &:first-child:last-child {
          max-height: calc(100vh - (3.75rem + 1px));
          height: calc(100vh - (3.75rem + 1px));
        }
      }
    }
  }

  .modal-header {
    align-items: center;

    & > button:first-child {
      order: 2;
    }
  }

  .modal-body {
    overflow-y: auto;
    padding-bottom: 2rem;

    .modal-header {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    .modal-footer {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    &.no-padding {
      padding: 0;
    }

    &:not(:first-child):not(:last-child) {
      max-height: calc(100vh - (11.5rem + 2px));
    }
    &:not(:first-child):last-child {
      max-height: calc(100vh - (7rem + 1px));
      // 1.75 + 1.75 + (1.5 + 2)
    }
    &:first-child:not(:last-child) {
      max-height: calc(100vh - (8rem + 1px));
    }
    &:first-child:last-child {
      max-height: calc(100vh - (3.75rem + 1px));
    }
  }

  .modal-footer {
    justify-content: center;

    .left { margin-right: auto; order: 1; }
    .right { margin-left: auto; order: 3; }
    .btn { margin: 0 5px; }

    & > *:first-child:not(.left):not(.right) {
      margin-left: auto;
      order: 3;
    }

    &::after {
      content: '';
      visibility: hidden;
      flex-grow: 1;
      order: 2;
    }
  }
}