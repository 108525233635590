#sellerPortal-list {
  .action-buttons {
    margin-bottom: .75rem;
  }

  .pager, .action-buttons {
    margin-left: .75rem;
    margin-right: .75rem;
  }

  .pager {
    text-align: center;
  }

  .item-list {
    .no-items {
      text-align: center;
      font-style: italic;
      padding: 2rem 0;
    }

    .image-count { text-align: center; }

    .auction-title {
      margin-bottom: 0;
      margin-top: .5rem;
    }

    a.item {
      grid-template-columns: 1fr 2.5rem;
      grid-template-areas:
        "title buttons"
        "category buttons";

      .title { grid-area: title; }
      .item-buttons { grid-area: buttons; }
      .category { grid-area: category; }
      .image-count { display: none; grid-area: imagecount; }
      .id { grid-area: id; }
      .lot-nmbr { grid-area: lotnmbr; }

      & > * {
        align-self: center;
      }
    }

    @include media-breakpoint-up(md) {
      header, a.item {
        display: grid;
        grid-template-columns: 1fr 15em 5em 5rem;
        grid-template-areas:
          "title category imagecount buttons";

        .image-count { display: inline; }
      }
    }

    &.tab-working-on {
      a.item {
        grid-template-columns: 1.2rem 1fr 5rem;
        grid-template-areas:
          "selector title buttons"
          "selector category buttons";

        .item-selector { grid-area: selector; }

        @include media-breakpoint-up(md) {
          grid-template-columns: 1.2rem 1fr 15em 5em 5rem;
          grid-template-areas:
            "selector title category imagecount buttons";
        }
      }
    }

    &.tab-approved {
      a.item {
        grid-template-columns: 5rem 1fr 2.5rem;
        grid-template-areas:
          "id title buttons"
          "category category buttons";
      }

      @include media-breakpoint-up(md) {
        header, a.item {
          grid-template-columns: 5rem 1fr 15em 5em 2.5rem;
          grid-template-areas:
            "id title category imagecount buttons";
        }
      }
    }

    &.tab-on-auction {
      a.item {
        grid-template-columns: 5rem 1fr 2.5rem;
        grid-template-areas:
          "id title buttons"
          "lotnmbr category buttons";
      }

      @include media-breakpoint-up(md) {
        header, a.item {
          grid-template-columns: 5rem 5rem 1fr 15em 5em 2.5rem;
          grid-template-areas:
            "id lotnmbr title category imagecount buttons";
        }
      }
    }
  }
}