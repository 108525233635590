.card-collapse {
  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    background-color: rgba($jma-gold, .1);

    padding: .375rem .625rem;
  }

  .card-body {
    padding: .625rem;
  }

  + .card-collapse {
    margin-top: .625rem;
  }
}