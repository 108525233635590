#myCards {

  #creditCard-list {
    header { display: none; }
    div.credit-card {
      display: grid;
      align-items: center;
      grid-template-columns: 2rem auto 1fr 3rem;
      grid-template-areas:
        "type number . actions"
        "valid expire expire actions";
    }
    /*
    type number name actions
    valid expire expire actions
    */

    .card-type {
      grid-area: type;
      text-align: center;
      font-size: 1.5rem;
    }
    .card-number { grid-area: number; }
    .card-name { grid-area: name; display: none; }
    .expire-date { grid-area: expire; }
    .card-valid { grid-area: valid; text-align: center; }
    .actions { grid-area: actions; text-align: center; }

    @include media-breakpoint-up(sm) {
      div.credit-card {
        grid-template-columns: 2rem 15rem 1fr 3rem;
        grid-template-areas:
          "type number name actions"
          "valid expire expire actions";
      }
      .card-name { display: inline; }
    }

    @include media-breakpoint-up(md) {
      header { display: grid; }
      .expire-date { text-align: center; }
      header, div.credit-card {
        align-items: center;
        grid-template-columns: 2rem 15rem 1fr 6rem 3rem 3rem;
        grid-template-areas:
          "type number name expire valid actions";
      }
    }
  //   @include media-breakpoint-up(lg) {
  //     header, a.invoice {
  //       grid-template-columns: 9rem 1fr 5.25rem 5rem 7rem 7rem;
  //     }
  //   }

  //   header.invoice {
  //     .invoice-total { text-align: center; }
  //     .invoice-balance { text-align: center; }
  //   }
  }

}