.dark-background {
  background-color: #000;
  color: #fff;
}

.brokers {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .broker {
    width: 45%;
    margin-top: 10px;

    h4 {
      font-size: 1.25em;
    }

    img {
      float: left;
      max-height: 10em;
      margin-right: 1em;
    }
  
    @include clearfix;
  }
}

#real-estate-drone {
  margin-top: 15px;

  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;

  iframe, object, embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

@import 'property-details';
@import 'property-listing';