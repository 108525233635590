a {
  text-decoration: none;

  &:hover, &:focus {
    text-decoration: underline;
  }
}

body {
  background: $body-background-color;
  color: $body-text-color;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;

  & > main {
    flex-grow: 1;

    & > .dark-background > .container {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }

    & > .container {
      padding-top: 1rem;
      padding-bottom: 1rem;

      .hero-img:first-child {
        margin-top: -1rem;
      }

      section:not(.split) {
        background: $section-background-color;
        color: $section-text-color;
      }

      section.split {
        & > main {
          background: $section-background-color;
          color: $section-text-color;
        }
      }
    }
  }
}

.nav-tabs + .tab-content {
  padding: 1rem 1rem 0 1rem;
  border: $nav-tabs-border-width solid $nav-tabs-border-color;
  border-top: none;
  border-bottom-left-radius: $nav-tabs-border-radius;
  border-bottom-right-radius: $nav-tabs-border-radius;
  margin-bottom: 1rem;

  &.no-padding {
    padding: 0;
  }
}

.password-meter {
  width: $password-meter-width;
  background: theme-color-level('danger', $alert-bg-level);
  z-index: 4;
  pointer-events: none;
  margin-top: $input-border-width;
  margin-bottom: $input-border-width;
  margin-right: $password-meter-position;
  margin-left: 0px - $password-meter-position - $password-meter-width;
  outline: 1px solid theme-color-level('danger', $alert-border-level);

  display: flex;
  align-items: flex-end;

  &::after {
    content: '';
    flex: 1 1 auto;
    height: 20%;
    background-color: theme-color-level('danger', $alert-color-level);
  }

  &[data-score="1"]::after {
    height: 40%;
  }

  &[data-score="2"] {
    background: theme-color-level('warning', $alert-bg-level);
    outline-color: theme-color-level('warning', $alert-border-level);
    &::after {
      height: 60%;
      background-color: theme-color-level('warning', $alert-color-level);
    }
  }

  &[data-score="3"] {
    background: theme-color-level('info', $alert-bg-level);
    outline-color: theme-color-level('info', $alert-border-level);
    &::after {
      height: 80%;
      background-color: theme-color-level('info', $alert-color-level);
    }
  }

  &[data-score="4"] {
    background: theme-color-level('success', $alert-bg-level);
    outline-color: theme-color-level('success', $alert-border-level);
    &::after {
      height: 100%;
      background-color: theme-color-level('success', $alert-color-level);
    }
  }
}

.input-group-phone {
  & > select, & > select.select2.form-control:not(.defined-width) + .select2-container {
    width: 6rem !important;
  }

  & > input[name$="_ext"] {
    width: 5rem !important;
    flex: 0 0 5rem;
  }

  & > div.intl-tel-input {
    flex: 1 1 auto;
  }
}

div.form-control.uppercase {
  text-transform: uppercase;
}

.all-uppercase {
  input:not(.normalcase) {
    text-transform: uppercase;
    &::placeholder {
      text-transform: none;
    }
  }

  select:not(.normalcase) {
    text-transform: uppercase;
  }
  
  select.select2:not(.normalcase) {
    & + .select2-container {
      .select2-selection__rendered {
        text-transform: uppercase;

        .select2-selection__clear {
          text-transform: lowercase;
        }
      }
    }
  }
}

.btn + .btn {
  margin-left: 5px;
}

input[type=number] {
  -moz-appearance: textfield;
  &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

body, p, h1, h2, h3, h4 {
  font-family: $primary-font;
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px solid;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

h1, h2, h3, h4 {
  font-weight: 800;
}

h1, h2 {
  font-size: 1.75rem;
  text-transform: uppercase;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

img {
  max-width: 100%;
  height: auto;
}

.large-dual-nav-links {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;

  a.btn-jma-gold.btn-inset {
    background-color: white;
    padding: 30px;
    display: flex;
    align-items: center;
    flex-grow: 1;
    margin: 0 15px;

    &:not(:disabled):not(.disabled).active:focus,
    &:not(:disabled):not(.disabled):active:focus {
      background-color: white;
    }

    @include media-breakpoint-up(md) {
      height: 274px;
      width: 35%;
    }
    @include media-breakpoint-up(lg) {
      height: 356px;
    }
    @include media-breakpoint-up(xl) {
      height: 430px;
    }
  }

  &.with-headings {
    h2 {
      margin: 0 15px;
      width: 100%;
      text-align: center;
    }

    @include media-breakpoint-up(md) {
      display: grid;
      grid-column-gap: 30px;

      grid-template-areas:
        "h1 h2"
        "img1 img2";

      a.btn-jma-gold.btn-inset { width: 100%; margin: 0; }
      h2:first-child {
        grid-area: h1;
      }
      h2:not(:first-child) {
        grid-area: h2;
      }
      a:not(:last-child) {
        grid-area: img1;
      }
      a:last-child {
        grid-area: img2;
      }
    }
  }
}

#scrollToTop {
  position: fixed;
  bottom: 50px;
  right: 40px;
  display: none;
  opacity: 0.8;
  border: 1px solid #000;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  small {
    font-weight: normal;
    line-height: 1;
    color: #999;
    font-size: .75em;
  }
}

hr {
  border: none;
  border-top: 1px solid #bebebe;
  margin-top: 20px;
  margin-bottom: 20px;
}

.hero-img {
  height: 420px;
  background-size: cover;
  background-position: center;
  display: flex;

  .caption-box {
    max-width: 500px;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: auto 0 auto 10%;
    padding-bottom: 30px;
    background-color: rgba(38, 38, 40, 0.75);

    & h2, p {
      color: white;
    }

    & h2 {
      width: 100%;
      text-transform: uppercase;
      margin: 0;
      padding: 20px 30px;
      background-color: rgba(38, 38, 40, 1);
      border-bottom: 1px solid $jma-gold;
    }
    & p {
      font-size: .85em;
      padding: 20px 30px;
    }

    .button {
      font-size: .75em;
      font-weight: bold;
      text-transform: uppercase;
      color: white;
      padding: 10px 20px;
      border: 1px solid white;
      text-decoration: none;
      border-radius: 25px;
      margin: 0 30px;
      
      &:hover {
        background-color: rgba(255, 255, 255, 0.25);
        border-color: rgba(255, 255, 255, 0.7);
        color: white;
      }
    }

  }

  @include media-breakpoint-down(xs) {
    .caption-box {
      margin: auto;
      h2 { font-size: 0.9em; }
    }
  }
}

%long-break {
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  hyphens: auto;
}

.nav-pills {
  &.square {
    .nav-link {
      border-radius: 0;
    }
  }

  @each $color, $value in $theme-colors {
    &.nav-pills-#{$color} {
      .nav-link.active,
      .show > .nav-link {
        background-color: $value;
        color: color-yiq($value);
      }
    }
  }
}

.hidden {
  display: none !important;
}

section {
  main {
    .nav-pills-jma-gold:first-child {
      border-bottom: 1px solid $jma-gold;
      margin-bottom: 1rem;
    }
  }
}

.auction-map {
  position: relative;
  padding-top: 450px;

  iframe {
    width: 100%;
    height: 100%;
    border: 0;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.modal-body .simple-choice {
  display: flex;
  justify-content: space-around;

  button {
    font-size: 1.5rem;
    background: transparent;
    border: 1px solid $jma-gold;
    padding: .5em;
    cursor: pointer;
    border-radius: .25em;
    
    i.fas {
      font-size: 3.5rem;
      color: $jma-gold;
    }

    &:focus {
      box-shadow: $btn-box-shadow, 0 0 0 $btn-focus-width rgba($jma-gold, .5);
      outline: none;
    }
  }
}