@import url('https://fonts.googleapis.com/css?family=Oswald:700');

@mixin starburst($burst-size, $burst-padding, $xs-mod) {
  .burst {
    position: relative;
    text-align: center;
    width: $burst-size;
    height: $burst-size;

    @include media-breakpoint-down(xs) {
      width: $burst-size - $xs-mod;
      height: $burst-size - $xs-mod;
    }

    &::before, &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: $burst-size;
      width: $burst-size;
      background: red;

      @include media-breakpoint-down(xs) {
        width: $burst-size - $xs-mod;
        height: $burst-size - $xs-mod;
      }
    }
    &::before {
      transform: rotate(30deg);
    }
    &::after {
      transform: rotate(60deg);
    }

    &.outer {
      background: #d2222c;
      z-index: 0;
      width: $burst-size + $burst-padding;
      height: $burst-size + $burst-padding;
      &::before, &::after {
        z-index: 0;
        background: #d2222c;
        width: $burst-size + $burst-padding;
        height: $burst-size + $burst-padding;
      }
      @include media-breakpoint-down(xs) {
        width: ($burst-size + $burst-padding) - $xs-mod;
        height: ($burst-size + $burst-padding )- $xs-mod;
        &::before, &::after {
          width: ($burst-size + $burst-padding) - $xs-mod;
          height: ($burst-size + $burst-padding )- $xs-mod;
        }
      }
    }
    &.inner {
      z-index: 1;
      background: #ffe401;
      top: $burst-padding / 2;
      left: $burst-padding / 2;
      &::before, &::after {
        z-index: 1;
        background: #ffe401;
      }
    }
  }

  .text {
    position: absolute;
    top: 0; left: 0;
    bottom: 0; right: 0;
    text-align: center;

    display: flex;
    justify-content: center;
    align-items: center;

    font-family: 'Oswald', serif;
    font-weight: 700;
    line-height: 1em;

    color: #d2222c;
    text-shadow:
      -1px -1px 0 #fff,
       1px -1px 0 #fff,
       -1px 1px 0 #fff,
        1px 1px 0 #fff;

    @include media-breakpoint-down(xs) {
      font-size: .75rem;
    }
  }
}

#kissimmee {
  .hero-img {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;

    h1 {
      margin-left: .5em;
      margin-bottom: .5em;
      font-family: 'Oswald', serif;
      font-weight: 700;
      // color: #e88900;
      // text-shadow:
      //   1px 1px 30px black,
      //   1px 1px 30px black;
      color: #d2222c;
      text-shadow:
        -2px -2px 0 #fff,
         2px -2px 0 #fff,
         -2px 2px 0 #fff,
          2px 2px 0 #fff;

      line-height: .75em;
      font-size: 3rem;

      @include media-breakpoint-down(sm) {
        font-size: 2.5rem;
      }
      @include media-breakpoint-down(xs) {
        font-size: 2rem;
      }

      span {
        font-size: .635em;
        
      }
    }

    .multiple-rings {
      position: absolute;
      top: calc(.75rem + 10px);
      right: calc(.75rem + 10px);

      transform: rotate(10deg);
      @include starburst(80px, 10px, 23px);
      @include media-breakpoint-down(xs) {
        top: calc(.75rem + 5px);
      }
    }

    // .catalog-link {
    //   position: absolute;
    //   bottom: calc(.75rem + 10px);
    //   right: calc(.75rem + 10px);
      
    //   transform: rotate(-10deg);
    //   @include starburst(80px, 10px, 23px);
    //   @include media-breakpoint-down(xs) {
    //     bottom: auto;
    //     right: auto;
    //     top: calc(.75rem + 5px);
    //     left: calc(.75rem + 10px);
    //   }
    // }
  }

  .ring-boxes {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    padding-top: 5px;

    .box {
      flex: 0 0 auto;
      margin: 5px;
      min-width: 15rem;
      max-width: 275px;
      width: 275px;
      position: relative;
      background-size: cover;
      background-position: center;

      &::before {
        content: '';
        display: block;
        padding-top: 100%;
      }

      .content {
        position: absolute;
        top: 0; left: 0; right: 0; bottom: 0;

        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        color: #fff;
        background-color: rgba(34.6, 34.6, 34.6, 0.6);

        h2 {
          margin-bottom: 0;
        }
        .date {
          font-weight: bold;
          font-style: italic;
          text-align: center;
        }
      }

      &:hover {
        .content {
          background-color: rgba(34.6, 34.6, 34.6, 0.3);
        }
      }

      @include media-breakpoint-between(md, lg) {
        margin: 5px 20px;
      }

      @media (max-width: 350px) {
        max-width: 100%;
        min-width: 50%;
      }
    }
  }

  .contact-us {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin: 2rem 0;
    font-size: 1.5em;
    padding: .75em;

    background-color: black;
    color: white;

    a {
      color: white;
    }
  }

  .link-bar {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 2rem;

    a {
      flex: 1 0 45%;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      text-align: center;

      border: 1px solid $section-within-border-color;
      padding: .5em;
      margin: 5px;

      font-size: 2rem;
      font-family: 'Oswald', serif;
      font-weight: 700;
      color: #d2222c;
      text-shadow:
        -2px -2px 0 #fff,
         2px -2px 0 #fff,
         -2px 2px 0 #fff,
          2px 2px 0 #fff;

      &.link-catalog {
        flex: 1 0 90%;
      }

      @include media-breakpoint-down(sm) {
        flex: 1 0 100%;
      }
    }
  }

  .sales-reps {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    padding-top: 5px;
    margin-bottom: 5px;

    .rep {
      flex: 0 0 auto;
      margin: 5px;
      min-width: 15rem;
      max-width: 275px;
      width: 275px;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      @include media-breakpoint-between(md, lg) {
        margin: 5px 20px;
      }

      @media (max-width: 350px) {
        max-width: 100%;
        min-width: 50%;
      }

      .details {
        display: flex;
        justify-content: space-between;
        line-height: 1em;
        margin-top: .25em;
      }
      .email {
        font-size: .85em;
      }

      .btn {
        align-self: flex-start;
      }
    }
    .rep--small {
      max-width: 180px;
      min-width: 180px;
      width: 180px;
      font-size: 0.85rem;

      a {
        font-size: 0.8rem;
      }
    }
  }
}

#kissimmee-vendors {
  h1 {
    text-align: center;
    padding: 0 1.25rem;
  }

  & > p {
    text-align: center;
    padding: 0 1.25rem;
    font-weight: bold;
  }

  .logos {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;

    img {
      max-width: 275px;
      max-height: 90px;

      margin: 15px;
    }
  }

  .vendors {
    padding-bottom: 1px;
  }

  .vendor {
    display: flex;
    align-items: flex-start;
    padding: 25px;
    margin: 1.25rem;
    border: 1px solid $section-within-border-color;

    & + .vendor {
      margin-top: 25px;
    }

    .vendor-logo {
      flex-shrink: 0;
      display: inline-flex;
      align-items: center;
      justify-content: center;

      width: 180px;
      height: 180px;
      padding: 10px;
      border: 1px solid $section-within-border-color;
      margin-right: 1.25rem;

      img {
        max-width: 100%;
        max-height: 100%;
      }

      @include media-breakpoint-down(md) {
        width: 100px;
        height: 100px;
        padding: 8px;
      }
      @include media-breakpoint-down(sm) {
        width: 75px;
        height: 75px;
        padding: 5px;
      }
      @include media-breakpoint-down(xs) {
        width: 50px;
        height: 50px;
        padding: 3px;
      }
    }

    .details {
      .website {
        @extend %long-break;
      }
    }
  }
}

.florida-modal {
  .ring {
    cursor: pointer;
    & + .ring {
      margin-top: 10px;
      padding-top: 10px;
      border-top: 1px solid #ccc;
    }

    .layout {
      display: flex;
      align-items: center;

      .label {
        white-space: nowrap;
        padding-left: 5px;
        font-weight: bold;
        &::after {
          content: ' - ';
        }
      }
      .desc {
        flex: 1 1 auto;
        padding: 0 5px;
      }
    }

    &:hover > .layout {
      background-color: #eac777;
      color: color-yiq(#eac777);
    }
  }
}