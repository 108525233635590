.ring-card {
  .category-list {
    columns: 15rem auto;

    padding-left: 1rem;
  }
}

#auction-details {
  .title-area {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: .5rem;

    #view-all {
      margin-left: auto;
    }
  }

  .hero-img {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
  }

  .ring-boxes {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    padding-top: 5px;

    .box {
      flex: 0 0 auto;
      margin: 5px;
      min-width: 15rem;
      max-width: 275px;
      width: 275px;
      position: relative;
      background-size: cover;
      background-position: center;

      &::before {
        content: '';
        display: block;
        padding-top: 100%;
      }

      .content {
        position: absolute;
        top: 0; left: 0; right: 0; bottom: 0;

        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        color: #fff;
        background-color: rgba(34.6, 34.6, 34.6, 0.6);

        h2 {
          margin-bottom: 0;
        }
        .date {
          font-weight: bold;
          font-style: italic;
          text-align: center;
        }
      }

      &:hover {
        .content {
          background-color: rgba(34.6, 34.6, 34.6, 0.3);
        }
      }

      @include media-breakpoint-between(md, lg) {
        margin: 5px 20px;
      }

      @media (max-width: 350px) {
        max-width: 100%;
        min-width: 50%;
      }
    }
  }

  .contact-us {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin: 2rem 0;
    font-size: 1.5em;
    padding: .75em;

    background-color: black;
    color: white;

    a {
      color: white;
    }
  }

  .sales-reps {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding-top: 5px;
    margin-bottom: 5px;

    .rep {
      flex: 0 0 auto;
      margin: 5px;
      min-width: 15rem;
      max-width: 275px;
      width: 275px;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      .rep-options {
        position: absolute;
        padding: 5px;
        top: 5px;
        right: 5px;
        color: #000;
        background: #fff;
        border-radius: 50px;
        box-shadow: 0 0 3px rgba(0, 0, 0, .25);
        z-index: 1050;
      }
      .rep-menu {
        top: 35px;
        right: 6px;
        left: auto;
        min-width: auto;
      }

      img {
        max-width: 100%;
        height: auto;
      }

      &.ui-sortable-placeholder {
        visibility: visible !important;
        border: 2px dashed #808080;
      }

      .rep-title {
        font-weight: bold;
      }

      .details {
        display: flex;
        justify-content: space-between;
        line-height: 1em;
        margin-top: .25em;
      }

      .email {
        align-self: flex-start;
      }

      a.email {
        font-size: .85em;
      }

      &.rep--large {
        .show-small {
          display: none;
        }
      }

      &.rep--small {
        max-width: 180px;
        min-width: 180px;
        width: 180px;
        .rep-title, .details {
          font-size: 0.85rem;
        }

        .details {
          flex-direction: column;
          line-height: 1.5;
        }

        a.email {
          font-size: 0.8rem;
        }

        .show-large {
          display: none;
        }
      }
    }
  }
}

.sidebar-auction-details {
  .staff-list {
    .salesrep {
      display: flex;
      font-size: 0.8rem;

      .details {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        overflow-x: hidden;
        overflow-wrap: break-word;
        padding-left: .35rem;

        .name {
          font-weight: bold;
        }
      }

      & + .salesrep {
        margin-top: .45rem;
      }
    }
  }
}

.sidebar-catalogs {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  flex-wrap: wrap;
  
  .flipsnack {
    max-width: 48.5%;
    display: flex;
    flex-direction: column;

    img {
      border-top-right-radius: .2rem;
      border-top-left-radius: .2rem;
      transition: opacity 0.4s ease;

      &:hover {
        opacity: 0.5;
      }
    }

    a:not(.btn) {
      flex: 1 1 auto;
    }

    .btn {
      border-top-right-radius: 0;
      border-top-left-radius: 0;
    }
  }
}