.account-section {

  & > header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: $section-header-background-color;
    color: $section-header-text-color;
    padding: 5px 15px;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
      align-items: stretch;
    }

    h2 { margin: 0; }
    a {
      color: $section-header-link-normal-color;
      &:hover {
        color: $section-header-link-hover-color;
      }
    }

    .back-with-buttons {
      display: inline-flex;
      justify-content: space-around;
      flex-wrap: wrap;
      // flex-direction: column;
      // @include media-breakpoint-up(sm) {
      //   flex-direction: row;
      // }
    }
  }
  
  .pager, .action-buttons {
    margin-left: .75rem;
    margin-right: .75rem;
  }

  .pager {
    text-align: center;
  }

  > main {
    .action-buttons:first-child {
      margin-top: .25em;
    }
  }

  &#myLocations {
    @import 'account-locations';
  }
}