.search {
  background-color: $jma-gold;
  padding: 15px 0;

  .search-fields {
    flex-direction: column;
    input {
      flex: 6 1 auto;
      display: block;
      padding: .375rem .75rem;
      font-size: 1rem;
      line-height: 2;
      color: #fff;
      background-color: $jma-gold;
      background-clip: padding-box;
      border: 1px solid #fff;
      transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }
  
    input::placeholder {
      color: #fff;
    }
  
    input:focus {
      color: #fff;
      background-color: $jma-gold;
      border-color: #fff;
      box-shadow: 0 0 0.2rem 0.2rem rgba(183,163,109,.5);
      outline: none;
    }
  
    .btn {
      color: #fff;
      background-color: #000;
      border-color: #000;
      border-radius: 0;
      text-transform: uppercase;
      font-weight: 700;
      margin-top: 20px;
      flex: 1 1 auto;
    }
  }

  @include media-breakpoint-up(md) {
    .search-fields {
      flex-direction: row;
      input {
        margin-right: 10px;
      }
      .btn {
        margin-top: 0;
      }
    }
  }
}

.view-all-auctions {
  text-align: center;
  margin: 25px;
  .btn {
    background-color: #000;
    color: #fff;
    font-size: 1em;
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 0;
    padding: 15px;
  }
  @include media-breakpoint-up(md) {
    font-size: 1.5em;
  }
}
.about-us {
  background-color: $jma-gold;
  color: #fff;
  text-align: center;
  padding: 20px;
  margin-bottom: 20px;

  h2, h3 {
    text-transform: uppercase;
    margin: 15px 0;
  }

  h3 {
    font-size: 1.5em;
  }

  h4 {
    font-size: 1.15em;
    font-weight: normal;
  }

  h5 {
    font-size: 1.15em;
    font-weight: bold;
  }

  .video-container {
    max-width: 800px;
    margin: 30px auto;
    .video {
      position: relative;
      padding-bottom: 56.25%;
      padding-top: 30px;
      height: 0;
      overflow: hidden;
      margin: 0 auto;
      border: 2px solid #fff;
    }
  }

  .video iframe,
  .video object,
  .video embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.boxes {
  display: grid;
  grid-column-gap: 30px;
  grid-row-gap: 20px;
  grid-template-columns: 1fr;
  grid-template-areas: 
    "reviews"
    "mission"
    "newsletter";

  .box {
    h2 {
      font-size: 1.5em;
      font-weight: normal;
    }
  }

  .reviews { 
    grid-area: reviews; 
  }
  .mission { 
    grid-area: mission;
    background-color: $jma-gold;
    color: #fff;
    padding: 30px;
    text-align: center;

    h2{
      margin-bottom: 30px;
    }
  }
  .newsletter { 
    grid-area: newsletter; 
    background-color: #000;
    color: #fff;
    padding: 30px;

    h2, h5 {
      text-align: center;
    }

    h2 {
      margin-bottom: 10px;
    }

    h5 {
      font-size: 0.9em;
      margin-bottom: 30px;
    }

    label {
      font-size: 0.9em;
    }

    input {
      padding: .375rem .75rem;
      font-size: 1rem;
      line-height: 2;
      color: #000;
      background-color: #868686;
      background-clip: padding-box;
      border-color: #000;
      border-radius: 0;
      transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;    
    }

    input:focus {
      background-color: #fff;
      border-color: #000;
      box-shadow: 0 0 0.2rem 0.2rem rgba(183,163,109,.25);
      outline: none;
    }

    .btn {
      color: #fff;
      background-color: $jma-gold;
      border-color: #000;
      border-radius: 0;
      text-transform: uppercase;
      font-size: 0.85em;
      align-self: flex-end;
    }
  }

  @include media-breakpoint-up(md) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas:
      "reviews mission newsletter";
  }
}
.auction-news {
  article {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    h2, p {
      flex: 1 1 auto;
    }
    h2 {
      font-size: 1.5em;
      margin-bottom: 20px;
    }
    a {
      font-weight: bold;
      color: $jma-gold;
    }
    img { 
      object-fit: contain;
      align-self: center;
      margin: 0 0 20px;
    }
    @include media-breakpoint-up(md) {
      flex-direction: row;
      img {
        max-width: 300px;
        margin-right: 35px;
      }
    }
  }
}