@function getMobileNestedBackground($color) {
  @if (lightness($color) > 50) {
    @return rgba(darken($color, 85%), .1);
  } @else {
    @return rgba(lighten($color, 85%), .1);
  }
}

@function getDesktopGrooveColor($color) {
  @if (lightness($color) > 50) {
    @return darken($color, 20%);
  } @else {
    @return lighten($color, 20%);
  }
}

.links {
  &, .menu {
    position: relative;

    > div {
      position: relative;
    }
  }

  input[type="checkbox"] {
    display: none;
  }

  @media screen and (min-width: 950px) {
    > div {
      .menu-link {
        display: inline-flex;
        width: 100%;

        &:not(:last-child)::after {
          font-family: "Font Awesome 5 Pro"; 
          font-weight: 900; 
          content: "\f0dd";
          padding: 0 10px;
          line-height: 1em;
          vertical-align: text-bottom;
        }
      }
    }

    label.mobile-toggle {
      display: none;
    }

    .submenu {
      display: flex;
      flex-direction: column;
      position: absolute;
      background-color: #FFF;
      min-width: 100%;
      text-align: left;
      z-index: 1001;

      .menu-link {
        display: none;
      }

      .subsubmenu {
        background-color: #FFF;
      }
    }

    &.click .menu-link {
      cursor: pointer;
    }

    &.click input[type=checkbox].submenu-toggle:checked ~ .submenu > div > .menu-link {
      display: inline-flex;
      &:not(:last-child)::after {
        margin-left: auto;
      }
    }

    &.hover div:hover {
      > .menu-link + .submenu {
        > div > .menu-link {
          display: inline-flex;

          &:not(:last-child)::after {
            margin-left: auto;
          }
        }
      }
    }

    &.click .submenu > div > input[type=checkbox].submenu-toggle:checked ~ .menu.submenu,
    &.hover .submenu > div:hover > .menu-link + .submenu {
      top: calc(1em + 28px);
      left: calc(100% - 5em);
      background: rgba(38,38,38,.1);
      z-index: 5;
      border-top: 2px groove getDesktopGrooveColor($header-background-color);
    }
  }

  @media screen and (max-width: 950px) {
    &, .menu:not(label) {
      > div {
        width: 100%;

        > label.mobile-toggle {
          position: absolute;
          right: 10px;
          top: 10px;

          &:nth-last-child(2) {
            display: none;
          }

          .mobile-toggle-icon {
            width: 25px;
            height: 25px;
            position: relative;
            
            .bar1, .bar2 {
              background-color: #777;
              transition: .4s;
              position: absolute;
            }
            .bar1 {
              width: 100%;
              height: 5px;
              margin: 0 auto;
              top: calc(50% - 2.5px);
            }
            .bar2 {
              height: 100%;
              width: 5px;
              margin: auto 0;
              left: calc(50% - 2.5px);
            }
          }
        }

        > .menu.submenu {
          overflow: hidden;
          transition: max-height .25s ease-in;

          > div {
            > .menu-link {
              transition: color .25s ease-in;
            }
          }
        }

        > input[type=checkbox].submenu-toggle {
          &:checked~label .mobile-toggle-icon .bar1  {
            transform: rotate(-135deg);
          }
          &:checked~label .mobile-toggle-icon .bar2 {
            transform: rotate(-135deg);
          }

          &:checked ~ .menu.submenu {
            max-height: 500px;
            display: inline-block;
            background-color: getMobileNestedBackground($header-background-color);
          }
          &:not(:checked) ~ .menu.submenu {
            max-height: 0;
            display: none;
            > div > {
              .menu-link {
                color: transparent;
              }
            }
          }
        }
      }
    }
  }
}