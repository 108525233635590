body > header {
  width: 100%;
  background: $header-background-color;
  color: $header-text-color;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

  .topbar-container {  
    background-color: $header-topbar-background-color;
    color: $header-topbar-text-color;
    height: 60px;
    max-width: 100%;
    padding: 8px 60px 8px 8px;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;

    .social-bar {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-end;
      align-items: center;

      & > div {
        padding-top: 3px;
      }

      i {
        margin-right: 5px;
      }

      a {
        color: $header-topbar-link-color;
        &:hover, &:focus {
          color: $header-topbar-hover-color;
        }
      }

      .loggedInButtons {
        display: flex;
        flex-flow: wrap;
        align-items: center;
        order: 1;
        
        > div {
          .menu-link {
            color: #000000 !important;
  
            &:hover {
              color: #000000 !important;
              background-color: transparent !important;
            }
          }
  
          &:last-child .menu-link {
            font-size: 20px;
            font-weight: bold;
          }
        }

        .pay-invoice {
          font-size: 12px;
          text-transform: uppercase;
          padding: 0 10px;
        }
  
        .login-register {
          font-size: 20px;
          font-weight: 700;
          text-transform: uppercase;
          padding: 0 10px;
          margin-right: 8px;
        }

      }

      .phone {
        margin-right: 24px;
        font-weight: bold;
        font-size: 18px;
      }

      .actions {
        text-align: center;

        a {
          display: inline-block;
          border: $btn-border-width solid $header-topbar-link-color;
          white-space: nowrap;
          @include button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $btn-line-height, $btn-border-radius);
          min-width: 8em;

          &:hover {
            box-shadow: 0 0 0.2rem 0.2rem rgba(183,163,109,.5);
          }

          + a {
            margin-left: .25em;
          }
        }
      }

      .social-media {
        text-align: right;
        font-size: 36px;
        padding: 2px 10px 0;
        order: 2;
        a {
          padding: 0 7px;
        }
      }
    }

    @media (max-width: 550px) {
      padding-left: 15px;
      padding-right: 15px;

      .social-bar {
        flex-flow: column nowrap;
      }
    }
  }

  .site-logo {
    margin-top: 3px;
    position: absolute;
  }

  .site-logo img {
    height: 95px;
    margin: -48px 80px 0 60px;
    max-width: calc(100% - 20px);
    vertical-align: middle;
  }

  .links-container {
    .menu-link {
      color: $header-text-color;
      text-transform: uppercase;
      font-weight: bold;
      font-size: $header-link-font-size;
      white-space: nowrap;
      &:hover, &:focus {
        color: $header-link-hover-color;
        text-decoration: none;
      }
    }
  }

  .header-container {
    display: flex;
    flex-flow: row wrap;
    width: 100%;

    @media (max-width: 1750px) {
      .site-logo {
        position: unset;
      }
    }

    @media (max-width: 1210px) {
      .site-logo {
        margin: 0 auto;
  
        img {
          margin: 0;
        }
      }
    }

    @media (max-width: 950px) {
      .site-logo { margin-top: 0; }
    }

  }

  @media (max-width: 1024px) {

    .topbar-container {
      height: max-content;
      padding-right: 8px;
      justify-content: center;
    }

    .header-container {
      height: max-content;
    }
  }

  @import 'common/navbar';
}