.no-responsive-items {
  padding: 50px 0;
  text-align: center;
  font-style: italic;
}

.responsive-list {
  display: grid;
  grid-template-rows: auto;
  // min-height: calc(100px + 1em);

  & > header {
    display: none;
    padding: 5px 0;
    font-weight: bold;
    border-bottom: 1px solid $section-header-background-color;
  }

  .responsive-row > * { padding: 0 5px; }
  .responsive-row:nth-child(even) {
    background-color: $alt-back-color;
    color: $alt-text-color;

    .custom-checkbox {
      .custom-control-input:not(:checked) ~ .custom-control-label::before {
        background-color: #fff;
      }
    }
  }

  a.responsive-row:not(header) {
    color: $body-text-color;
    &:hover {
      text-decoration: none;
      background-color: $jma-gold;
      color: color-yiq($jma-gold);
    }
  }

  .responsive-row:not(header) {
    display: grid;
    grid-template-rows: auto;
    padding: 5px 0;
  }

  .responsive-row + .responsive-row {
    border-top: 1px solid $section-header-background-color;
  }
}