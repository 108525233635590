.auction-grid {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex-wrap: wrap;

  .auction {
    max-width: 100%;
    flex: 1 1 auto;
    background-color: #efe9e9;
    margin: 10px;
    display: flex;
    flex-direction: column;
    border: 1px solid #ccc;
    border-width: 1px 0 0 1px;
    box-shadow: 2.5px 2.5px 2.5px 0px rgba(0, 0, 0, 0.6);
    transition: transform 250ms;

    &:hover {
      transform: scale(1.015);
      z-index: 999;
    }

    .auction-details {
      padding: 15px;
      flex: 1 1 auto;
      h3 {
        font-size: 1em;
      }
      .location {
        font-size: 0.9em;
        margin-bottom: 8px;
      }
      .auction-times {
        display: flex;
        .starts, .ends {
          color: #681817;
          font-weight: bold;
          flex: 1 1 auto;
          text-align: center;
          padding: 5px;
          h4 {
            font-size: 1em;
          }
        }
      }
      .time-remaining-container {
        display: flex;
        justify-content: center;
        .time-remaining:not(:empty) {
          background-color: #999;
          color: #fff;
          border-radius: 20px;
          padding: 3px 20px;
          font-size: 0.8em;
          font-weight: bold;   
          text-align: center;     
        }
      }
    }
    .auction-actions{
      background-color: $jma-gold;
      text-align: center;
      color: #fff;
      font-weight: bold;
      text-transform: uppercase;
      width: 100%;
      border-radius: 0;
      border-width: 1px 0 0 0;
    }
  }
  @include media-breakpoint-up(md) {
    flex-direction: row;
    .auction {
      max-width: 380px;
    }
  }
}