.news-item-container {
  .news-card {
    img {
      max-width: 33.33333%;
      flex: 0 0 33.33333%;
      padding: 4px;
      border: 1px solid #ddd;
      margin-right: 15px;
      align-self: flex-start;
    }

    .excerpt {
      flex: 1 1 auto;
    }

    + .news-card {
      margin-top: 1.5em;
    }
  }
}

#news-detail-page {
  .social-links {
    margin-bottom: .5em;
  }

  .featured-image {
    padding: 4px;
    border: 1px solid #ddd;
  }
}