$comment-border: #ddd;
$comment-border-width: 3px;

.comments-container {
  display: flex;
  flex-direction: column;

  .comment-bubble {
    min-width: 50%;
    max-width: 75%;
    position: relative;
    border: $comment-border-width solid $comment-border;
    border-radius: $comment-border-width * 2;
    padding: .5rem;
    margin-bottom: .5rem;

    &::before {
      content: ' ';
      position: absolute;
      width: 0;
      height: 0;
      bottom: auto;
      border: ($comment-border-width * 2.5) solid;
    }
    &::after {
      content: ' ';
      position: absolute;
      width: 0;
      height: 0;
      bottom: auto;
      border: (($comment-border-width * 2.5) * 0.6) solid;
    }

    &.left {
      margin-left: $comment-border-width * 6;
      align-self: flex-start;

      &::before {
        left: 0 - ($comment-border-width * 6);
        right: auto;
        top: $comment-border-width;
        border-color: $comment-border $comment-border transparent transparent;
      }
      &::after {
        left: 0 - ($comment-border-width * 3);
        right: auto;
        top: ($comment-border-width * 2);
        border-color: white white transparent transparent;
      }
    }
    &.right {
      align-self: flex-end;
      margin-right: $comment-border-width * 6;

      &::before {
        left: auto;
        right: 0 - ($comment-border-width * 6);
        top: $comment-border-width;
        border-color: $comment-border transparent transparent $comment-border;
      }
      &::after {
        left: auto;
        right: 0 - ($comment-border-width * 3);
        top: ($comment-border-width * 2);
        border-color: white transparent transparent white;
      }
    }

    .comment-text {
      white-space: pre-line;
    }
  }
}