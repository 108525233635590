$sidebar-width: 4;

@mixin sidebar-size() {
  width: 100%;

  @include media-breakpoint-up(md) {
    flex-shrink: 0;
    width: percentage($sidebar-width / $grid-columns);
  }
}

.sidebar-page {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  section {
    flex-grow: 1;
    @include media-breakpoint-down(sm) {
      order: 1;
    }
  }

  aside {
    @include media-breakpoint-down(sm) {
      order: 2;
      padding-left: 0;
      padding-right: 0;
    }
  }

  aside:first-child {
    padding-right: 15px;
    @include sidebar-size();
  }
  aside:last-child {
    padding-left: 15px;
    @include sidebar-size();
  }

  @include media-breakpoint-up(md) {
    flex-direction: row;
  }
}

.sidebar-search-form {
  margin-bottom: 15px;
}

.sidebar-upcoming-auctions {
  margin-bottom: 1rem;

  a.auction {
    display: block;
    color: #000;
    border-style: solid;
    border-color: #ccc;
    border-left-width: 1px;
    border-right-width: 1px;
    border-top-width: 1px;
    border-bottom-width: 0;
    background: #f4f4f4;
    padding: 15px;
    box-shadow: 0 1px 1px 0 rgba(200, 200, 200, 0.7);

    &:hover {
      background: #fff;
      text-decoration: none;
    }

    .auction-header {
      flex: 1 1 auto;
    }
  }
}

.side-logos {
  .experian-auto-check {
    background: linear-gradient(to bottom, #7db9e8 0%, #2989d8 0%, #05377d 100%);
    border: 3px outset #2989D8;
    text-align: center;
    box-shadow: 4px 4px 4px #555;
  }
}

.sidebar-widget {
  min-height: 20px;
  padding: 20px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}

.sidebar-map-widget {
  margin-top: .25em;

  .auction-map {
    padding-top: 100%;
  }
}