.custom-select.is-invalid~.invalid-feedback, .custom-select.is-invalid~.invalid-tooltip, .form-control.is-invalid~.invalid-feedback, .form-control.is-invalid~.invalid-tooltip, .was-validated .custom-select:invalid~.invalid-feedback, .was-validated .custom-select:invalid~.invalid-tooltip, .was-validated .form-control:invalid~.invalid-feedback, .was-validated .form-control:invalid~.invalid-tooltip {
  display: none;
}

.custom-select.is-invalid,
.form-control.is-invalid,
.was-validated .custom-select:invalid,
.was-validated .form-control:invalid {
  &:hover, &:focus {
    & ~ .invalid-feedback, & ~ .invalid-tooltip {
      display: block;
    }
  }
}

label.form-label-sm {
  font-size: $font-size-sm;
  margin-bottom: .25rem;
}

.form-group-sm {

  .form-group, &.form-group {
    margin-bottom: .5rem;

    label {
      font-size: $font-size-sm;
      margin-bottom: .25rem;
    }
  }

}

.input-group-success {
  .input-group-text {
    color: theme-color-level('success', $alert-color-level);
    @include gradient-bg(theme-color-level('success', $alert-bg-level));
    border-color: theme-color-level('success', $alert-border-level);
  }
}

.input-group.inner-input-group {
  .input-group-prepend, .input-group-append {
    position: absolute;
    z-index: 4;
    top: 0;
    bottom: 0;
    pointer-events: none;
  }

  .input-group-prepend {
    left: 0;
  }
  .input-group-append {
    right: 0;
  }

  .input-group-text {
    background-color: transparent;
    color: #6c757d;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    border: none;
  }

  & > .form-control:not(:first-child) {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;

    padding-left: calc(1.25em + 0.75rem);
  }
  & > .form-control:not(:last-child) {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;

    padding-right: calc(1.25em + 0.75rem);
  }
}

.input-group > .input-group-text {
  &:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  &:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.form-row {
  .form-group {
    margin-bottom: .25rem;
    label {
      margin-bottom: 0;
    }
  }
}

.form-group, .form-group-sm {
  &.required {
    label {
      position: relative;
  
      &::after {
        content: "*";
        color: $form-feedback-invalid-color;
        font-weight: bold;
        position: absolute;
        right: -.5em;
      }

      > small {
        position: fixed;
        margin-left: 1em;
        margin-top: 2px;
      }
    }
  }
}

div.form-control[disabled], fieldset:disabled div.form-control {
  background-color: $input-disabled-bg;
}