#location-list {
  justify-content: center;

  .card {
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 1em;
    min-width: 230px;

    .card-footer {
      display: flex;
      justify-content: flex-end;
    }
  }
}