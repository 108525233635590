#myInvoices {

  #invoice-list {
    a.invoice {
      grid-template-columns: auto 1fr 7rem;
      grid-template-areas:
        "number . total"
        "title . balance"
        "date . status";
    }

    .invoice-number { grid-area: number; }
    .auction-title { grid-area: title; }
    .invoice-date { grid-area: date; }
    .invoice-status { grid-area: status; text-align: right; }
    .invoice-total { grid-area: total; text-align: right; }
    .invoice-balance { grid-area: balance; text-align: right; }


    @include media-breakpoint-up(md) {
      header, a.invoice {
        display: grid;
        grid-template-columns: 7rem 1fr 5.25rem 5rem 7rem 7rem;
        grid-template-areas:
          "number title date status total balance";

        .invoice-date { text-align: center; }
        .invoice-status { text-align: center; }
      }
    }
    @include media-breakpoint-up(lg) {
      header, a.invoice {
        grid-template-columns: 9rem 1fr 5.25rem 5rem 7rem 7rem;
      }
    }

    header.invoice {
      .invoice-total { text-align: center; }
      .invoice-balance { text-align: center; }
    }
  }

}

#invDetails {
  #invoice-items {
    display: grid;
    grid-template-rows: auto;
    min-height: calc(100px + 1em);

    .invoice-lot {
      display: grid;
      grid-template-columns: auto repeat(4, 1fr);
      grid-template-rows: auto;
      grid-template-areas:
        "title title title title title"
        "qty bid amount premium tax"
        "expenses expenses expenses expenses expenses";

      &:nth-child(even) {
        background-color: $alt-back-color;
        color: $alt-text-color;
      }
      & > * { padding: 0 5px; }
    }

    .invoice-lot + .invoice-lot {
      border-top: 1px solid $section-header-background-color;
    }

    .invoice-lot + .invoice-lot.sublot {
      border-top: none;
    }
    .invoice-lot.sublot {
      padding-left: 1.5rem;
    }

    .title { grid-area: title; }
    .description { display: none; grid-area: description; }
    .qty { grid-area: qty; }
    .bid {
      grid-area: bid;
      padding-left: 0;
      text-align: left;
      &::before {
        content: 'x';
        padding-right: 5px;
      }
    }
    .amount {
      grid-area: amount;
      text-align: left;
    }
    .premium {
      grid-area: premium;
      text-align: left;
    }
    .tax {
      grid-area: tax;
      text-align: left;
    }
    .expenses {
      grid-area: expenses;
      .expense {
        display: grid;
        grid-template-columns: 1fr auto 7rem;
        grid-template-rows: auto;
        grid-template-areas:
          ". desc amount";
        margin: 5px 0;

        .exp-desc { grid-area: desc; }
        .exp-amount { grid-area: amount; text-align: right; }
      }
    }

    header.invoice-lot {
      font-weight: bold;
      .bid { display: none; }
    }

    .amount, .premium, .tax {
      text-align: right;
    }

    @include media-breakpoint-up(md) {
      header.invoice-lot {
        .qty, .bid, .amount, .premium, .tax {
          display: block;
        }
      }

      .invoice-lot {
        display: grid;
        grid-template-columns: 25% 1fr 3rem repeat(4, 1fr);
        grid-template-areas:
          "title description qty bid amount premium tax"
          "expenses expenses expenses expenses expenses expenses expenses";

        .qty { text-align: right; }
        .bid {
          text-align: right;
          &::before { content: ''; }
        }
      }
    }

    footer.totals {
      border-top: 1px solid $section-header-background-color;
      .total {
        display: grid;
        grid-template-columns: 1fr auto 7rem;
        grid-template-areas: ". label amount";

        .label {
          grid-area: label;
          font-weight: bold;
        }
        .amount { grid-area: amount; }
      }
    }
  }
}