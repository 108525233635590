.property-details {
  padding: 0 5px;

  .listing-agent {
    font-size: .75rem;
  }

  .slider {
    margin: 0 -5px;

    .slider-status {
      &.real-estate-status-SOLD {
        background-color: rgba(189, 54, 47, 0.75);
      }
    }
  }

  .slideshow {
    .slideshow-image {
      // display: flex;
      // justify-content: center;
      // align-items: center;
      height: 420px;
      text-align: center;
      position: relative;
      display: inline-flex !important;
      align-items: center;
      justify-content: center;

      img {
        max-height: 100%;
        max-width: 100%;
        display: inline;
        cursor: pointer;
      }
      .blurred {
        filter: blur(15px);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: -1;
      }

      & + .slideshow-image {
        display: none;
      }
    }
  }

  h3 {
    font-weight: normal;
    border-top: 1px solid $section-header-background-color;
    margin-top: 20px;
    padding-top: 10px;
  }

  .description {
    border-top: 1px solid $section-header-background-color;
    margin-top: 20px;
    padding-top: 10px;

    .desc-with-buttons {
      display: flex;
      justify-content: space-between;

      h3 {
        border-top: none;
        margin: 0;
      }
    }
  }

  .detail-title {
    display: flex;
    font-size: 1.75rem;
    font-weight: bold;

    .title {
      flex-grow: 1;
    }
    .asking-price {
      margin-left: 10px;
      margin-top: 5px;

      white-space: nowrap;

      .auction-info {
        text-align: right;
        font-size: .75em;
        font-weight: normal;

        & > span + span {
          &::before {
            content: ' - ';
          }
        }
      }
    }
  }

  .quick-stats {
    display: flex;
    flex-wrap: wrap;

    margin-left: 5px;
    margin-top: 10px;

    .map-link {
      font-size: 3em;
      &:hover {
        text-decoration: none;
      }
    }

    .stat {
      margin: 0 15px;
      text-align: center;
      line-height: 1em;
      &:first-child {
        margin: 0 15px 0 0;
      }

      &.simple {
        strong {
          display: block;
          font-size: 2em;
          line-height: 1em;
        }
      }

      &.line > div {
        div:first-child {
          font-size: 2em;
          line-height: 1em;
        }
      }

      &.icon {
        display: inline-flex;
        i {
          font-size: 3em;
          margin-right: .25em;
        }
      }
    }

    .stat-buttons {
      margin-left: auto;

      .btn {
        display: inline-block;
        padding-top: 0;
        padding-bottom: 0;
        line-height: 1em;
        font-size: .75em;

        i {
          display: block;
          font-size: 2em;
        }

        
      }
    }
  }

  .rle-fields {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: calc(-1rem - 5px);

    .rle-field {
      margin: 10px 10px;
      max-width: 200px;

      .label {
        text-align: center;
        font-weight: bold;
      }

      .value {
        text-align: center;
      }
    }
  }

  .auction-notes {
    text-transform: uppercase;
  }

  #roomsTab {
    ul {
      list-style-type: none;
      padding: 0;
      column-count: 2;
    }
  }
}

.modal-body {
  #propertyMapEmbed {
    display: block;
    border: 0;
    width: 100%;
    height: 100%;
  }
}

#sliderModal {
  .slider-content {
    max-height: calc(100% - 120px);
    height: calc(100% - 120px);
    .slick-list {
      height: 100%;

      .slick-track {
        height: 100%;

        .slick-slide {
          text-align: center;

          & > div {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            
            img {
              max-height: 100%;
              width: auto !important;
            }
          }
        }
      }
    }
  }
  .slider-nav {
    img {
      width: 120px;
      height: 120px;
    }
  }
}

#real-estate-contact-form {
  display: flex;
  margin-left: -5px;
  margin-right: -5px;

  .map {
    order: 1;
    flex-grow: 1;

    #contactMapEmbed {
      display: block;
      border: 0;
      width: 100%;
      height: 100%;
    }

    .image-container {
      height: 100%;
      text-align: center;

      img {
        width: 100%;
      }
    }
  }

  .form-result {
    display: none;
    width: 375px;
    background-color: $section-header-background-color;
    color: $section-header-text-color;
    padding: 24px;
    min-height: 300px;

    h3 {
      margin-top: 0;
      padding-top: 0;
    }
  }
  .form-details {
    width: 375px;
    background-color: $section-header-background-color;
    color: $section-header-text-color;
    padding: 24px;

    h3 {
      margin-top: 0;
      padding-top: 0;
    }

    .input-group + .input-group {
      margin-top: 1rem;
    }
  }

  @include media-breakpoint-down(lg) {
    display: block;

    .map {
      #contactMapEmbed {
        height: 350px;
      }

      .image-container {
        img {
          max-height: 350px;
          width: auto;
        }
      }
    }
    .form-details {
      width: 100%;
    }
  }
}
// .property-grid {
//   display: flex;
//   flex-direction: column;

//   nav {
//     display: flex;
//     flex-direction: column;
//     padding-top: 10px;

//     a {
//       padding: 4px;
//       border-radius: 0 2px 2px 0;
//       text-align: center;
//       color: $section-text-color;

//       &.active {
//         pointer-events: none;
//         background-color: $jma-gold;
//         color: color-yiq($jma-gold);
//         text-decoration: none;
//       }

//       &:not(.active) {
//         border: 1px solid $jma-gold;
//         border-width: 1px 1px 1px 0;

//         &:hover {
//           background-color: #96896e;
//           color: color-yiq(#96896e);
//           text-decoration: none;
//         }
//       }
//     }
//   }

//   .asking-price {
//     font-size: 1.25em;
//   }

//   .attributes {
//     display: flex;
//     flex-wrap: wrap;

//     .attribute {
//       width: 40%;
//       flex-grow: 1;
//       margin-top: 20px;

//       label {
//         text-transform: uppercase;
//         color: $jma-gold;
//         margin-bottom: 0;
//       }
//       .value {
//         font-size: 1.5em;
//       }
//     }
//   }

//   @include media-breakpoint-up(md) {
//     display: grid;
//     grid-template-columns: 45% 8em 1fr;
//     grid-template-areas: 
//       "property-tabs property-nav property-atts"
//       "property-desc property-desc property-desc";

//     .tab-content {
//       grid-area: property-tabs;
//     }
//     nav {
//       border-left: 3px solid $jma-gold;
//       margin-left: 3px;
//       margin-right: 10px;
//       grid-area: property-nav;
//     }
//     .property-details {
//       grid-area: property-atts;
//     }
//     .description {
//       grid-area: property-desc;
//     }
//   }
// }