$slick-font-family: 'Font Awesome 5 Pro';
$slick-prev-character: '\f053';
$slick-next-character: '\f054';
$slick-dot-character: '\f111';

@import 'slick-carousel/slick/slick.scss';
@import 'slick-carousel/slick/slick-theme.scss';

.slick-slider {
  .slick-next, .slick-prev {
    z-index: 1;
    height: 75px;
    width: 60px;
    opacity: 0;
    transition: opacity .25s ease-in-out;
    background-color: #515151;
  }

  &:hover {
    .slick-next, .slick-prev {
      opacity: .75;

      &:hover {
        opacity: .85;
      }
    }
  }
  
  .slick-prev {
    left: 0px;
  }
  .slick-next {
    right: 0px;
  }

  .slick-slide {
    height: auto;
  }
}

.slider {
  position: relative;

  .slider-status {
    position: absolute;
    bottom: 10px;
    left: 10px;
    color: white;
    background-color: rgba(51, 51, 51, 0.75);
    padding: 0 5px;
    z-index: 1;
  }

  .slick-pages {
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: 12px;
    color: white;
    background-color: rgba(51, 51, 51, 0.75);
    padding: 0 5px;
    z-index: 1;

    .current {
      margin-right: 3px;
    }
    .max {
      margin-left: 3px;
    }
  }
}

.slick-slide,
.slick-slider .slick-track,
.slick-slider .slick-list {
  backface-visibility: hidden;
}
.slick-cloned {
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
}