#frmLogin, #frmPasswordReset {
  margin: 0 auto;

  header { text-align: center; }
  section { padding: 5px; border-radius: .1rem; }

  @include media-breakpoint-up(sm) { 
    section { padding: 5px 10px; }
  }
  @include media-breakpoint-up(md) {
    width: 600px;
    section {
      border-radius: $border-radius-sm;
      padding: 10px;
    }
  }
  @include media-breakpoint-up(lg) {
    border-radius: $border-radius;
    section { padding: 20px; }
  }
  
  /*
  $btn-border-radius:           $border-radius !default;
  $btn-border-radius-lg:        $border-radius-lg !default;
  $btn-border-radius-sm:        $border-radius-sm !default;
  */
}