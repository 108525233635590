#myDashboard {

  main {
    padding: 0 15px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    @include media-breakpoint-up(sm) {
      flex-direction: row;
      flex-wrap: wrap;
      align-items: flex-start;
    }

    .btn {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      border-radius: 0;
      text-align: center;
      width: 180px;
      height: 180px;
      margin: 20px 0;
      padding: 5px;

      i {
        display: block;
        font-size: calc(180px - 4rem);
      }

      @include media-breakpoint-up(md) {
        width: 200px;
        height: 200px;
        font-size: 1.25rem;
      }
      @include media-breakpoint-up(lg) {
        width: 250px;
        height: 250px;
        font-size: 1.5rem;
        i { font-size: calc(250px - 6rem); }
      }
    }
  }
}