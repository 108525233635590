.read-more {
  a.excerpt:not([href]), a.full:not([href]) {
    color: $link-color;
    text-decoration: $link-decoration;
    cursor: pointer;
    display: block;
  
    @include hover() {
      color: $link-hover-color;
      text-decoration: $link-hover-decoration;
    }
  }
  .excerpt, .full {
    display: block;
  }

  &:not(.expanded) {
    .full, a.full {
      border: 0;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }
  }

  &.expanded {
    .excerpt, a.excerpt {
      display: none;
    }
  }
}