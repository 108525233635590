#category-list {
  margin-top: 2rem;
  list-style: none;
  padding-left: 0;

  @include media-breakpoint-up(md) {
    column-count: 2;
  }
  @include media-breakpoint-up(lg) {
    column-count: 3;
  }

  li {
    break-inside: avoid;
  }

  a.btn {
    break-inside: avoid;
    display: flex;
    justify-content: space-between;
    align-items: center;

    span.name {
      text-align: left;
      white-space: normal;
    }

    span.badge {
      margin-left: .25rem;
    }

    // &.subcategory {
    //   span.name::after {
    //     content: '...';
    //   }
    // }
  }
}

#inventory-list {
  h1 .category-name {
    color: #999;
    font-size: .75em;
    margin-left: .5em;
    
    &.no-transform {
      text-transform: none;
    }
  }

  .no-items {
    padding: 1em;
  }

  .title-area {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: .5rem;

    h1 {
      overflow-wrap: break-word;
      max-width: 100%;
      margin-bottom: 0;
    }

    .btn.back {
      margin-left: auto;
    }
  }

  #auction-ring-details {
    .alert {
      p:last-child {
        margin-bottom: 0;
      }
    }
  }

  #item-list {
    padding: 15px;
  }
  .item-card {
    table tbody tr:first-child {
      td, th {
        border-top: none;
      }
    }
    
    & + .item-card {
      margin-top: 15px;
      padding-top: 15px;
      border-top: 1px solid #bebebe;
    }
  }

  .item-card-container.loading {
    position: relative;
    &::after {
      content: '';
      position: absolute;
      left: 0; top: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.3);
    }
  }

  .pager {
    flex-direction: row;
    justify-content: center;
  }
}

#inventory-details {
  .primary-details {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(md) {
      flex-direction: row;

      & > table {
        width: auto;
        flex: 1 1 auto;
      }
    }

    table tbody tr:first-child {
      td, th {
        border-top: none;
      }
    }
  }

  #item-details {
    padding: 15px;
  }

  .photo-gallery {
    display: flex;
    flex-wrap: wrap;

    a {
      margin: 5px;
    }
  }

  ul.document-gallery {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}