.nav-tabs.bottom-border {
  border-bottom: 2px solid $nav-tabs-border-color;

  .nav-link {
    position: relative;
    border-color: transparent transparent $nav-tabs-border-color;
    color: $nav-tabs-link-active-color;

    &:hover {
      color: $link-hover-color;
    }

    &::after {
      content: '';
      background: $jma-gold;
      height: 2px;
      position: absolute;
      width: 100%;
      left: 0px;
      bottom: -2px;
      transition: all 250ms ease 0s;
      transform: scale(0);
    }

    &.active::after,  &:hover::after {
      transform: scale(1);
    }
  }
}