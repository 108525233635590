$btn-primary-bg: theme-color('primary');
$btn-primary-color: color-yiq($btn-primary-bg);
$border-radius-base: $border-radius;
$gray-lighter: $gray-100;
$gray-light: $gray-300;

$screen-sm-min: map-get($grid-breakpoints, md);
$screen-md-min: map-get($grid-breakpoints, lg);
$screen-lg-min: map-get($grid-breakpoints, xl);

@import "eonasdan-bootstrap-datetimepicker/src/sass/bootstrap-datetimepicker-build.scss";

div.datepickerbutton {
  cursor: pointer;
}