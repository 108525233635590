body > footer {

  background-color: $footer-background-color;
  color: $footer-text-color;
  font-size: 0.85rem;

  .topbar {
    padding: 30px 60px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    border-bottom: 4px solid #fff;
    align-items: center;

    & > div {
      margin-bottom: 10px;
    }

    h3 {
      margin-top: 20px;
      margin-bottom: 20px;
      font-size: 1.65rem;
      font-weight: 800;
    }

    p {
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
        "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
        "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    }

    a {
      color: $footer-link-normal-color;
      &:hover { color: $footer-link-hover-color; }
    }

    ul {
      list-style-type: none;
      padding-left: 0;
    }

    .associations {
      max-width: 420px;

      .association-images img {
        max-height: 100px;
        max-width: 204px;
        margin-right: 20px;
        margin-bottom: 20px;
      }
    }

    .jma-locations {
      max-width: 500px;
  
      img {
        width: 100%;
      }

    }

    .location-text {
      font-size: 22px;
      a {
        font-weight: bold;
        font-size: 1rem;
      }
    }

    .app-icons {
      display: flex;
      flex-flow: column nowrap;

      img {
        width: 280px;
        
        &.google-play {
          margin-bottom: 30px;
        }
      }
    }

    @include media-breakpoint-up(sm) {
      font-size: 1rem;
    }
  }

  .bottombar-container {
    background-color: $footer-bottombar-background-color;
    color: $footer-bottombar-text-color;

    .bottombar {
      width: 100%;
      padding: 20px 60px;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;

      p {
        margin-bottom: 0;
        font-size: 20px;
        font-weight: bold;

        a {
          color: #fff;
        }
      }
    }
  }

  ul {
    list-style-type: none;
    padding-left: 0;
  }
}