.tile-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .tile {
    margin: 20px 30px;
    width: 320px;
    border: 1px solid #ccc;
    box-shadow: 2.5px 2.5px 2.5px 0px rgba(0, 0, 0, 0.6);
    text-align: center;
    display: inline-flex;
    flex-direction: column;
    transition: transform 250ms;
    background-color: $section-background-color;
    color: $section-text-color;
    position: relative;
    z-index: 0;

    &:hover {
      transform: scale(1.015);
      z-index: 999;
    }

    .tile-badge {
      width: 75%;
      position: absolute;
      top: 190px;
      left: -6px;
      background-color: $jma-gold;
      color: color-yiq($jma-gold);
      border-radius: 2px 0 0 0;
      pointer-events: none;

      &::before {
        content: '';
        display: inline-block;
        width: 0;
        height: 0;
        left: 0;
        position: absolute;
        bottom: -10px;
        z-index: -10;
        border: 5px solid;
        border-color: $jma-gold transparent transparent transparent;
      }

      &::after {
        content: '';
        position: absolute;
        right: -0.6em;
        border-width: 0.75em 0.5em;
        border-style: solid;
        border-color: $jma-gold transparent $jma-gold $jma-gold;
      }

      &.real-estate-status-SOLD {
        background-color: #bd362f;
        color: color-yiq(#bd362f);

        &::before {
          border-top-color: #bd362f;
        }
        &::after {
          border-color: #bd362f transparent #bd362f #bd362f;
        }
      }
    }

    .tile-image {
      img {
        display: block;
        max-width: 100%;
        height: auto;
      }
    }

    .tile-details { padding: 10px; }

    .tile-title {
      font-size: 1.1em;
      line-height: 1;
    }
    .tile-description {
      font-size: .8em;
    }

    a.tile-title, .tile-title a {
      color: $section-text-color;

      &:hover {
        color: $jma-gold;
        text-decoration: none;
      }
    }

    .tile-info {
      margin-top: 10px;
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
  }
}